@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;



/* ====================================================
    Fonts
   ==================================================== */

@font-face {
	font-family: "Karbon-Regular";
	src: url("../fonts/karbon-regular-webfont.eot"); /* IE9 Compat Modes */
	src: url("../fonts/karbon-regular-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */  
  url("../fonts/karbon-regular-webfont.otf") format("opentype"), /* Open Type Font */  
  url("../fonts/karbon-regular-webfont.svg") format("svg"), /* Legacy iOS */  
  url("../fonts/karbon-regular-webfont.ttf") format("truetype"), /* Safari, Android, iOS */  
  url("../fonts/karbon-regular-webfont.woff") format("woff"), /* Modern Browsers */  
  url("../fonts/karbon-regular-webfont.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Karbon-Light";
	src: url("../fonts/karbon-light-webfont.eot"); /* IE9 Compat Modes */
	src: url("../fonts/karbon-light-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */  
  url("../fonts/karbon-light-webfont.otf") format("opentype"), /* Open Type Font */  
  url("../fonts/karbon-light-webfont.svg") format("svg"), /* Legacy iOS */  
  url("../fonts/karbon-light-webfont.ttf") format("truetype"), /* Safari, Android, iOS */  
  url("../fonts/karbon-light-webfont.woff") format("woff"), /* Modern Browsers */  
  url("../fonts/karbon-light-webfont.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Karbon-Medium";
	src: url("../fonts/karbon-medium-webfont.eot"); /* IE9 Compat Modes */
	src: url("../fonts/karbon-medium-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */  
  url("../fonts/karbon-medium-webfont.otf") format("opentype"), /* Open Type Font */  
  url("../fonts/karbon-medium-webfont.svg") format("svg"), /* Legacy iOS */  
  url("../fonts/karbon-medium-webfont.ttf") format("truetype"), /* Safari, Android, iOS */  
  url("../fonts/karbon-medium-webfont.woff") format("woff"), /* Modern Browsers */  
  url("../fonts/karbon-medium-webfont.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Karbon-Bold";
	src: url("../fonts/karbon-bold-webfont.eot"); /* IE9 Compat Modes */
	src: url("../fonts/karbon-bold-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */  
  url("../fonts/karbon-bold-webfont.otf") format("opentype"), /* Open Type Font */  
  url("../fonts/karbon-bold-webfont.svg") format("svg"), /* Legacy iOS */  
  url("../fonts/karbon-bold-webfont.ttf") format("truetype"), /* Safari, Android, iOS */  
  url("../fonts/karbon-bold-webfont.woff") format("woff"), /* Modern Browsers */  
  url("../fonts/karbon-bold-webfont.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Karbon-Semibold"; 
	src: url("../fonts/karbon-semibold-webfont.eot"); /* IE9 Compat Modes */
	src: url("../fonts/karbon-semibold-webfont.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */  
  url("../fonts/karbon-semibold-webfont.otf") format("opentype"), /* Open Type Font */  
  url("../fonts/karbon-semibold-webfont.svg") format("svg"), /* Legacy iOS */  
  url("../fonts/karbon-semibold-webfont.ttf") format("truetype"), /* Safari, Android, iOS */  
  url("../fonts/karbon-semibold-webfont.woff") format("woff"), /* Modern Browsers */  
  url("../fonts/karbon-semibold-webfont.woff2") format("woff2"); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
}

/* ====================================================
    Global
   ==================================================== */
body {
	background-color: $white;
}

.bg-color{
  background-color: #ededed;
}

a {
  color:#0a0a0a;
  text-decoration:underline;
}


p,li, ul, ul.bullets{
  line-height: 1.1em;
  margin-bottom:0.5em;
}

.fontS16 {
  font-size: 16px;
}
.fontS18 {
  font-size: 18px;
}
.fontS20 {
  font-size: 20px;
}
.fontS22 {
  font-size: 22px;
}
.fontS24 {
  font-size: 24px;
}
.fontSemiBold {
  font-family: "Karbon-Semibold";
}

small {
  font-size:15px;

  &.time {
    font-size: 11px;
  }
}

h1{
      font-family: "Karbon-Semibold";
      color: $yellow;
      line-height: 0.8em;
      font-size: 42px;
      }

h2{
    font-family: "Karbon-Regular";
    color: $blue;
    font-size: 48px;
    line-height: 1em;
    text-transform: uppercase;
    margin-left: -3px;
}

h3{
  font-family: "Karbon-Semibold";
  font-size: 34px;
  color: $blue;
  line-height: 1em;

  &.sub-title {
    font-family: "Karbon-Regular";
  }
  &.sub-title2 {
    font-family: "Karbon-Regular";
    text-transform:uppercase;
  }
}

h4{
    color: $white;
    font-size: 22px;
    line-height: 1em;
    font-family: "Karbon-Medium";
}

h5 {
  font-family: "Karbon-Semibold";
  font-size: 20px;
  line-height:1.1em;

  &.sub-title{
    color:$blue;
    margin-bottom: 25px;
  }
  &.sub-title2{
    color:$blue;
    font-family: "Karbon-Regular";
    margin-bottom: 25px;
    font-size: 24px;
    text-transform:uppercase;

    span {
      font-family: "Karbon-Medium";
      font-size: 30px;
    }
  }

  &.sub-title3{
    color:$blue;
    font-size:18px;
  }

  &.sub-title4{
    font-family: "Karbon-Medium";
    text-transform:uppercase;
  }

  &.sub-titleBlack {
    font-size: 24px;
    text-transform:uppercase;
  }


}

h6{
    font-family: "Karbon-Semibold";
    font-size: 20px;
    color:$blue;
    line-height: 1em;
}

hr{

    border-bottom: 1px solid $blue;
    margin: 40px 0px;

}

b{
  font-family: "Karbon-Semibold";
}
strong{
  font-family: "Karbon-Bold";
}


sup {
	line-height: 0px;
	font-size: 100%;
  top:0;
}

.grid-container{
	/*margin-top: 20px;*/
   // background-color: $white;
    
}

.url-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

ul.bullets li{
	list-style-type:none;
}
ul.bullets li::before{
	content: "\25A0";
  color: #ffd451;
  font-weight: bold;
	font-size:18px;
	position:absolute;
	margin-top: 0px;
	margin-left: -21px;
}
ul.bullets li:last-child{
	margin-bottom:0;
}

ul.sub-bullets li{
	margin-bottom:3px;
}
ul.sub-bullets li::before{
	content: "\25E6";
	font-size: 18px;
	margin-top: 0px;
}



.white-box{ background-color:#FFFFFF}
.white-link{ color:$white;
    &:hover{color:$yellow;}
}

.blue-txt{
    color: $blue;
}
.yellow-txt{
    color: $yellow;
}

.yellow-txt-box{
	background-color:#F36E21;
}

.blue-txt-box{
  background-color:$blue;
  color:$white;
  font-size: 20px;
  padding: 20px;
  margin-bottom: 20px;


  h2{
    color:$white;
  }
  a {
    color: inherit;
    text-decoration: underline;
  }
  a.button {
    color:$blue;
    text-decoration: none;
  }
  table {
    font-size: 18px;

    tbody{
      background-color:inherit;
      border: none;
    }
    tbody tr:nth-child(even) {
      background-color:inherit;
      border-top: 1px solid $white;
      border-bottom: 1px solid $white;
    }
    td{
      vertical-align: top;

      

      p {
        margin-bottom:0;
      } 
    }
    .width { 
      width:125px;
    }
    
  }
  .borderR {
    border-right: 1px solid $white;
  }
  &.infusionAd {
     font-size:16px;

     .txt1, .txt2, .txt3, .txt4,.txt5,.txt6,.txt7 {
      text-transform:uppercase;
     }

     .txt1 {
        font-family: "Karbon-Regular";
        font-size: 24px;
        
     }
     .txt2 {
        font-family: "Karbon-Medium";
        font-size: 26px;
     }
     .txt3 {
        font-family: "Karbon-Semibold";
        font-size: 62px;
        margin-bottom: -15px;
     }
     .txt4 {
        font-family: "Karbon-Semibold";
        font-size: 37px;
     }
     .txt5 {
        font-family: "Karbon-Semibold";
        font-size: 102px;
        color:$yellow;
        margin-bottom: -15px;
     }
     .txt6 {
        font-family: "Karbon-Semibold";
        font-size: 42px;
        margin-bottom: -2px;
     }
     .txt7 {
        font-family: "Karbon-Regular";
        font-size: 26px;
     }
     .txt8 {
        font-family: "Karbon-Semibold";
        font-size: 30px;
        margin-bottom: 5px;
     }

     .box1 {
      width:230px;
     }
     .box2 {
      width:100px;
     }
     .box3{
      width: 400px;
     }
     .box1,.box2,.box3 {
      display:inline-block;
      vertical-align:top;
     }


     hr {
      border-bottom: 1px solid $white;
      margin: 20px 0;
     }
  }
  
}

.blue-txt-box-p{  
  padding: 63px 65px 40px;
}

.blue-txt-box-p2{  
  padding: 20px 20px 20px 35px;
}


#home .blue-txt-box {
  
  position:relative;

  .arrow, .arrow2 {
    position:absolute;
    right:0;
    left:0;
    margin-left:auto;
    margin-right:auto;
    bottom:25px;
    width:80%;
  }

}

.btn-alignment .gray-txt-box {
  
  position:relative;

  .arrow, .arrow2 {
    position:absolute;
    right:0;
    left:0;
    margin-left:auto;
    margin-right:auto;
    bottom:25px;
    width:88%;
  }
}

.btn-alignment-nobox {
  
  position:relative;

  .download {
    position:absolute;
    right:0;
    left:0;
    margin-left:auto;
    margin-right:auto;
    bottom:0px;
    width:93%;
    margin-bottom: 0;
  }
}

.gray-txt-box{
  background-color:#979797;
  color:$white;
  padding: 30px 35px 25px;
  margin-bottom: 20px;

  h2{
    color:$white;

    &.yellow-txt {
      color:$yellow;
    }
  }

  h3 {
    font-family: "Karbon-Regular";
    color:$white;
    text-transform:uppercase;
    line-height: 1.1em;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
  a.button {
    color:$blue;
    text-decoration: none;
  }

  .borderR {
    border-right: 1px solid $white;
  }

  hr {
    border-bottom: 1px solid $white;
  }
}

#home .blue-txt-box-p{  
  padding: 36px 51px 18px;
}


.marginT20{margin-top: 20px;}
.marginT40{margin-top: 40px;}
.marginT60{margin-top: 60px;}
.marginT80{margin-top: 80px;}
.marginB0{ margin-bottom:0px;}
.noIndent{ text-indent: 0!important;}

.talignR{
  text-align:right;
}





.borderT20{ border-top: 20px solid #fff}
.borderB20{ border-bottom: 20px solid #fff}
.borderR{border-right: 1px solid $blue}
.borderL{border-left: 1px solid $blue}

.padding20{padding:20px;}

.infobox{ 	
    background:url('../img/icon_info.svg') no-repeat;
    background-size: 50px auto;
    background-position: 20px 15px;
    background-color: $blue;

    padding: 15px 10px 10px 85px;
    color: $white;
}




.videos {
  
  h6 {
    text-transform:uppercase;
    font-family: "Karbon-Medium";
    margin-top: 5px;
    margin-bottom: 20px;
  }
}


.cL, .cR{       
    margin-top: 30px;
 }

.special_p{
  p{
    margin-bottom:0.5rem;
  }

  a {
    color:inherit;
    text-decoration:underline;
  }
}

.grid-padding-x > .cell.extra-p {
  padding-right: 7rem;
  padding-left: 7rem;
}

.vAlign .cell{
  margin-top: auto;
  margin-bottom:auto;
}

.extra-p2 .cell{
  padding-right: 4rem;
  padding-left: 4rem;
}

.jump:before {
    content: "";
    display: block;
    padding-top: 200px; /* header height + empty space */
    margin-top: -200px; /* header height to degrees padding’s space */
}

.specialAlign .cell{
  position:relative;

  .arrow, .arrow2 {
    position:absolute;
    bottom:0;
    width: 92%;
  }
}


.study-spacing{
  
  text-transform: uppercase;
}


#learn_about .grid-margin-x, #margin-x-fix .grid-margin-x{
  margin-left: inherit;
  margin-right: inherit;
}


.privacy-table {
  tbody {
    border: none;
    background-color: #ededed;

    td{
      padding: 0 5px 0 0;
      vertical-align: top;
    }
  }

  tbody tr:nth-child(even) {
    background-color: #ededed;
  }
}




/* ====================================================
    Button Styles
   ==================================================== */
.button {
    color:$blue;
    background-color: $yellow;
    font-family: "Karbon-Semibold";
    text-transform: uppercase;
    font-size: 15px;
    padding: 1em 1em 0.7em;

    &:hover {
      background-color: #fee28e;
      color:$blue;
    }

    &.2lines {
      padding-top: 0.4rem;
      padding-bottom: 0.2rem;
      padding-right: 106px;
    }
}
.share_btn{
    background:url('../img/icon_share.svg') no-repeat;
    background-size: 20px auto;
    background-position: right 5% top 8px;
    background-color: $yellow;
    padding-right:60px;
    width:270px;
    text-transform: uppercase;
    text-align: left;
    text-decoration:none;        
}


.arrow, .arrow:focus{  
    background:url('../img/icon_arrow.svg') no-repeat;
    background-size: 11px auto;
    background-position: right 3% top 10px;
    background-color: $yellow;
    padding-right:60px;    
    width:100%;
    text-align:left;
    text-decoration:none;
    letter-spacing: 0.1em;
}

.arrow2, .arrow2:focus{  
    background:url('../img/icon_arrow.svg') no-repeat;
    background-size: 11px auto;
    background-position: right 3% top 17px;
    background-color: $yellow;
    padding-right:60px;    
    width:100%;
    text-align:left;
    text-decoration:none;
    letter-spacing: 0.1em;
}

.download, .download:focus{  
    background:url('../img/icon_download.png') no-repeat;
    background-size: 20px auto;
    background-position: right 4% top 12px;
    background-color: $yellow;
    padding-right:60px;    
    width:100%;
    text-align:left;
    text-decoration:none;
    letter-spacing: 0.1em;
}

ul.button-menu li {
  width: 100%;
}
.button-menu .menu.vertical li{
  padding-left: 18px;
  padding-right: 18px;
}

.dropdown.menu.button-menu > li > a {
  padding: 0.85rem 1rem;
}
.dropdown.menu.button-menu > li.is-dropdown-submenu-parent > a::after {
 content:none;
}
.dropdown.menu.button-menu > li.is-active > a {
  background-color: $yellow;
}
.dropdown.menu.button-menu > li.is-active > a::after {
  right: 11px;
  display: block;
  width: 0;
  height: 0;
  border: inset 10px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1779ba transparent transparent;
  left: auto;
  margin-top: -3px; 
}

.dropdown.menu.button-menu > li.opens-right > .is-dropdown-submenu {
  width: 100%;
  z-index: 1;

  li {

    &:last-child a{
      border-bottom: none;
    }

    a {
      color: #0a0a0a;
      border-bottom: 1px solid $blue;
      padding: 0.7rem 1rem  0.6rem 0;

      &:hover {
        color: $blue;
      }

    }
  }

  
}






/* ====================================================
    Icon Sizes
   ==================================================== */
.icon-size1{
  height: 89px;
  margin-bottom: 20px; 
}
.icon-size2 {
  width: 110px;
  margin-bottom: 20px; 
}


/* ====================================================
    Navigation Area
   ==================================================== */

.topbar{background-color:#0076c0;}

.sticky-container {
  position: fixed;
  z-index: 2;
  width:100%;
  top:0

}

.nav-bar {
    color: $white;
	  padding-top: 19px 0px ;
    transition: 0.4s;	 

  	.accordion-menu .is-accordion-submenu a{
  		padding:0.5rem 1rem;
  	}

    #desktop-menu{ 
        width:100%; 
        .product_home{
            background-color: $white;
            background:url('../img/icon_plus.svg') no-repeat;
            background-size: 20px auto;
            background-position: right 10% top 45%;
            background-color: $white;
            padding-left: 15px;
            display: flex;
            align-items: center;  
            width:12%;
            height:60px;
            a{
                color:$blue!important;
            }
        }
        
        #menu-top{
            width:100%;
            font-size: 14px;
            
            li { 
              padding:0.7rem 1rem 0.6em;
              line-height: 32px; 
              color:#ffd451;
              margin-bottom: 0;
            }

            a{
                color: $white; 
                padding:0;
                line-height: 32px;
            }
            a:hover, a:focus{  color: $yellow;  }

            }        

    }
    .mob_topbar{
         display: flex;
         align-items: center;    
         justify-content: center; 
         //position: fixed;
        // top:0px;
        a{  width:50%;
            text-align:center;
            font-size: 13px;
            text-decoration:none;
        }
    }

    .menu-icon{
        float: right;
        width:35px;
        margin-top: 20px;
    }
    
    .hero-logo{
	    width:170px;
        
        @media print, screen and (min-width: 64em){ // large desktop view
          width:200px;
          margin-left: 5px;
        }
    }
    
    .graybar{

        background-color: rgba(0, 36, 58, 0.5);
          
        .gray-menu-content{
            
            
            li {
                color:$white;
                font-family: "Karbon-Semibold";
                font-size: 15px;
                margin-bottom:0;

            }
             li a{
                color:$white;
                padding: 1.95rem 1rem;

                &::after{
                   border-color: transparent transparent transparent; 
                }
                &:hover {
                    background-color: $white;
                    color:$blue;
                }
                &:focus{
                    background-color: $white;
                    color:$blue;
                }
                &.active{
                    //background-color: $white;
                    color:$yellow;
                }
             }
             li:hover > a {
                    background-color: $white;
                    color:$blue;
                }
            .menuB_group{ 
                width:400px !important;
                    
                    li a{ 
                        color:$black;
                        padding: 0.8rem 0rem 0.6rem;
                        border-bottom: 1px solid #2492da;
                        
                        &:hover{
                             color:$blue;  
                             background-color: $white;
                        }

                        &.active{
                        //background-color: $white;
                        color:$blue;
                        }        
                        
                    }
                    li:last-child a {
                      border-bottom: none;
                    }
                    .is-active > a{
                         color:$blue; 
                         background-color: $white;
                    }
              }
            
            }
    }

}


.sticky.is-anchored{ 
    z-index:1001;
    position: fixed;
    top: 0 !important;
}
 
.nav-bar.is-stuck .graybar{
    background: $white;

    li a{
        color:$blue;

        &:hover {
          color:$yellow;
        }
    }

    li:hover > a {
      color:$yellow;
    }


}

.is-dropdown-submenu {
  border:none;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}

.menu.vertical{
    background-color: $white;
    line-height: 2em;
    li {
       padding-left: 10px;
       padding-right: 10px;
       margin-bottom:0;

        
        &:last-child{ border: none;}
        ul li{
            a{
            color:$black;
                padding:1rem 1rem!important;
                 &:hover {
                    background-color: $blue;
                    color:$white;
                }     
                
            }
        }
    }
    
}
.accordion-menu a {padding:1rem 1rem!important;
  
  &.active {
    color: $yellow;
  }

}

.hamburger {
  display:inline;
}

.menu-icon.blue-icon::after {
  background: $blue;
  -webkit-box-shadow: 0 7px 0 $blue, 0 14px 0 $blue;
  box-shadow: 0 7px 0 $blue, 0 14px 0 $blue;
}

#mobile-menu {
  max-height:70vh;
  overflow-y:scroll;
  box-shadow: 0px 7px 10px rgba(0,0,0,0.4);
  position:fixed;
  z-index:1001;
  width: 100%;
  top: 89px;

  .accordion-menu .nested.is-accordion-submenu {
    margin-left:0;
  }
  .menu.vertical li ul li a {
        padding: 1rem 0.4rem !important;
        border-bottom:1px solid #2492da;

         &.active {
          color: $yellow;
        }

  }
  .menu.vertical li ul li:last-child a {
        border-bottom:none;
  }
}

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a:active {
  color:$yellow;
}

.otherlinks {
  background-color:$blue;

  a {
    color: $white;
  }
}

.mobile-scroll-off{
  overflow: hidden;
}

/* ====================================================
   hero
   ==================================================== */

.hero{
    position: relative;
    border-bottom: 25px solid $white;
    background-color: $white;
    min-height: 465px;
    margin-top: 50px;
    background-repeat: no-repeat;
    
    h1 {
      text-transform: uppercase;
      margin-bottom:0;
    }
    .heroimg {
        position: fix;
        
        @media print, screen and (min-width: 64em){ // large desktop view
         width:100%;
         height: auto;
        }
    }
    

    .headers{
       padding-top: 180px; 
 
    }

    .jnjlogo{
      margin-top: 35px;

      img {
        width: 199px;
      }
    }

    .headers-footnote {
      /*position:absolute;
      bottom:21px;*/
      color:$white;
      margin-top: 25px;

      small {
        font-size: 12px;
      }
    }

    
    &.sub {
    min-height: 375px;

      .jnjlogo{
        margin-top: 10px;

        img {
          width: 162px
        }
      }
      .headers {
        padding-top: 140px;
      }

    }
    
}

.line-logo .hero.sub .headers {
  padding-top: 170px;
}

.twoline-logo .hero.sub .headers {
  padding-top: 155px;
}

/* ====================================================
    Landing page
   ==================================================== */
#home{
    .cL, .cR{
    margin-top: 40px;
    
    }
}

/* ====================================================
    clinical_information
   ==================================================== */

.tabs{
    background: none;
    border: none;
    
}
.tabs-title{
  
   // border: 5px solid #ededed ;
    
     a{
        background: #bacacf;
        color:#000000!important;
        text-align: center;
        padding:8px 8px 3px 8px;
        font-size: 18px;
        font-family: "Karbon-Medium";
        text-transform: uppercase;
         margin:5px;
     }
     a:focus, a[aria-selected='true']{
       background :#005889;
        color:$white!important;
    }
    
}


.tabs-content{
  
   border:none;
   background: none; 
    .tabs-panel{
        padding-top: 0px;
        width:100%;
        .tab-box{
         background:#f8f8f8;
         border:1px $blue solid;
         padding:20px 0px;
         display: flex;
         align-items: center;    
         justify-content: center;    
         .c-header{
            text-align: center;
        } 
        img{ 
            
           display: block;
           width: 80%;
           min-width:100px;
           max-width: 450px; /*actual image width*/
           height: auto; /* maintain aspect ratio*/
           margin: auto; /*optional centering of image*/

        }
        p{ text-align: center;}
    }
    .footer{
        font-size: .8em; 
        margin-top: 5px;
        p{
         margin-bottom: 0px;
         text-indent: -.5em;
         text-align: left!important;    
        }
    }

}
}



.clinical_information{
    .cL, .cR{
        
        @media print, screen and (min-width: 64em){ // large desktop view
            margin: 30px 0px;
        }
    
   }
   .Ybtns{
       p{
        &:first-child{ 
            margin-top:20px;
             @media print, screen and (min-width: 64em){ // large desktop view
            margin-top:80px;
            }
        }
           }
    }    
}


/* ====================================================
    Accordion
   ==================================================== */
.accordion {
  background: inherit;
}
.accordion-title {
  color:$white;
  font-size: 18px;
  padding: 1.25rem 2.8rem 1.1rem 55px;
  background:$blue;
  text-decoration:none;
}

.accordion-title::before{
  content:'\2295';
  font-size: 39px;
  margin-top: -0.4em;
}
.is-active > .accordion-title::before{
  content:'\2296';
 
}

.accordion-title::after {
  content: 'Q: ';
  position: absolute;
  top: 50%;
  left: 1rem;
  margin-top:-0.8rem; 
  color:$yellow;
  font-family: "Karbon-Bold";
  font-size: 24px;
}
.accordion-content {
  background-color:inherit;
  border: none;
  padding: 1rem 1rem 1rem 80px;
}
.accordion-content::before {
  content: 'A: ';
  position: absolute;
  top:1;
  left:1;
  margin-top:0.2rem; 
  margin-left: -41px;
  color:$blue;
  font-family: "Karbon-Bold";
  font-size: 24px;
}
.accordion-title:hover, .accordion-title:focus{
  background-color:$blue;
  color:$white;

  &:hover{
    color:$white;
  }
}

:last-child > .accordion-content:last-child {
  border-bottom: none;
}

/* ====================================================
    important-safety-info
   ==================================================== */
.isi_ind_Txt{
     background-color: $white;

     a {
      color:$blue;
      text-decoration:none;
     }
    
     
    .content_box{
        position: relative;
        padding:25px 22px 25px;
        

    }
}
.s_header{
        background-color: $blue;
        color:$white;
        font-family: "Karbon-Semibold";
        font-size: 1.6em;
        line-height: 1.2em;
        padding: 6px 22px 2px;
        
        &.ind {
          @media print, screen and (min-width: 64em){ // large desktop view
            border-left: $white 1px solid;
          }
        }
        
 
    }
.backtotop{
    position: absolute; 
    bottom:3%;
    right:5%; 
}



#section-isi{ 
    position: fixed; 
    height:25%;
    z-index:1000;
    overflow-y: hidden;
    bottom: 0;
    right:0px;
    left:0px;
    background-color:$white;

    
    
    &.mob_isi{  
        max-height:100%;
        height:auto;

        &.open{
          top:0;
        }

        .content_box {
          padding-top: 10px;
          height: 10vh;
          
        }

        .borderL {
          border-left: none;
        }

        .s_header{
            font-size: 1.2em;
            position:relative;

            &:after{
            content: "+";
            font-size: 1.5em;
            position: absolute;
            right: 22px;
            color: $white;
            }
            &.isiOn:after  {
               content:"-" ;
            }  
                
         }
     
        
        
        .sISI{  
            &.On{
                height:75vh;
                overflow-y: hidden;
                
                .content_box{
                    height:71vh;
                    overflow-y: auto;
                }
            }
        }
        .sIND{
        

            &.On{
                height:86.5vh;  // was 85
                overflow-y: hidden;
                background-color: #FFF;  
                .content_box{
                    height:81vh; // was 85
                    overflow-y: auto;
                }
            }
        }
    
    }
}

.isi-header{
  background-color:$blue;
}

#isi-waypoint:before {
    content: "";
    display: block;
    padding-top: 150px; /* header height + empty space */
    margin-top: -150px; /* header height to degrees padding’s space */
}

.isi-btn {
  display:block;
  position:absolute;
  right:25px;
  top: 20px;
  z-index:1010;
  width: 23px;
}

.sIND{
 position:relative;
}

.isi_page {
  .isi_ind_Txt {
    background-color: inherit;

    .s_header {
      display: none;
    }

    .content_box {
      padding: 0;

      &.borderL{
        border-left: none;
      }
    }

  }
}


/* ====================================================
    footer
   ==================================================== */
footer{
  background-color: $blue;

  .grid-container{
    padding: 30px 23px 30px;
    
    color:$white;
  }

  img {
    margin-top: 10px;
  }

  .logo-align img {
    float: right;
  }

  a {
    text-decoration: none;
  }
    
}



/* ====================================================
    RESPONSIVE
   ==================================================== */
/* Small only */
@media screen and (max-width: 39.9375em) {
  
  h2 {
    font-size:36px;
  }
  h3 {
    font-size:28px;
  }
  .grid-padding-x > .cell.extra-p {
      padding-right: inherit;
      padding-left: inherit;
  }

  .m-resourceIMG{
    margin-bottom: 10px;
  }

  ul.bullets li::before, ul.sub-bullets li::before {
    font-size: 12px;
  }

  .hero {
    margin-top: 0px;
    min-height: 440px;

    h1 {
      font-size: 34px;
    }

    h4 {
      font-size: 16px;
    }

    .jnjlogo {
      margin-top: 8px;

      img {
        width: 150px
      }
    }

    .headers-footnote {
      margin-top: 10px;
      line-height: 0.9em;
    }
    small{
      font-size: 9px;
    }

    &.sub {
      .headers {
        top: 34%;
      }
    }

  } 

  #home .blue-txt-box, .btn-alignment .gray-txt-box, .btn-alignment-nobox {
    .arrow, .download, .arrow2 {
      position:inherit;
      width: 100%;
    }
  }

  #home .blue-txt-box-p{
    padding-left: 0px;
    padding-right: 0px;
  }
  .blue-txt-box-p{  
    padding: 45px 45px 20px;
  }

  .borderR, .blue-txt-box .borderR,.gray-txt-box .borderR{
    border-right:none;
  }
  .borderL{
    border-left:none;
  }

  .blue-txt-box.infusionAd {
      .box3 {
        width: 100%;
      }
  }
  

  #section-isi.mob_isi .s_header {
    padding-bottom: 4px;
    padding-top: 9px;
    font-size: 1.2em;
    line-height:0.8em;

    &:after {
       margin-top: -1px;
    }
  }

  .backtotop {
    position:initial;
    margin-left:3.2%;
    margin-bottom: 30px;
  }

  .specialAlign .cell{
    .arrow,.arrow2 {
      position:inherit;
      margin-bottom: 20px;
      width: 100%;
    }
  }

}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  
  .hero {
    margin-top: 0px;
  }

  #home .blue-txt-box-p{
    padding-left: 0px;
    padding-right: 0px;
  }

  .blue-txt-box-p{  
    padding: 63px 45px 40px;
  }


}

/* Large and up */
@media screen and (min-width: 64em){
  .graybar {
    .grid-x > .large-3 {
      width: 19%;
    }
    .grid-x > .large-9 {
      width: 80%;
    }
  }

  #home .blue-txt-box {
    /*min-height: 290px;*/
  }

  #learn_about .blue-txt-box{
    /*min-height: 582px;*/

  }

  .isi-last{
    position: absolute;
    bottom:15%;
    right:-25px;
  }

  .rotate {
    transform: rotate(-90deg);
    /* Safari */
    -webkit-transform: rotate(-90deg);
    /* Firefox */
    -moz-transform: rotate(-90deg);
    /* IE */
    -ms-transform: rotate(-90deg);
    /* Opera */
    -o-transform: rotate(-90deg);
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  }
  


}

@media screen and (max-width: 320px) {
  .hero {


    h1 {
      font-size: 24px;
    }

    h4 {
      font-size: 14px;
    }

    .jnjlogo{
      margin-top: 1px;

      img {
        width: 130px;
      }
    }

    .headers-footnote {
      margin-top: 8px;

    }

    &.sub {
      .headers {
        top: 40%;
      }
    }
    
  }
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
  .hero {
    h1 {
      font-size: 34px;
    }

    h4 {
      font-size: 18px;
    }

    .jnjlogo {
      margin-top: 5px;

      img {
        width: 150px;
      }
    }

    .headers-footnote {
      margin-top: 10px;
      line-height: 0.9em;
    }

    &.sub {
      h1 {
        font-size: 30px;
      }

      .jnjlogo {
        margin-top: 5px;
      }
    }

  } 


}

@media screen and (min-width: 1024px) and (max-width: 1169px) {
  .hero {
    h1 {
      font-size: 34px;
    }

    h4 {
      font-size: 18px;
    }

    .jnjlogo {
      margin-top: 15px;
    }

    &.sub .headers {
      top: 49%;
    }

  } 

  .nav-bar .graybar .gray-menu-content li{
    font-size: 14px;
  }

  .nav-bar .graybar .gray-menu-content li a {
    padding:1.6rem 0.3rem;
  }
}



/* ====================================================
    SweetAlert Modal
   ==================================================== */
.swal2-container.swal2-shown {
  background-color: rgba(0, 0, 0, .75) !important;
}

.swal2-popup {
  width: 48em !important;
  border-radius: 0px !important;
  background-color: #ffffff !important;
  padding-bottom: 35px !important;
  padding:1.25em 2em 2em !important;

  &.HCP_popup {
    padding-top: 5.5em !important;
    background-image:url('../img/logo_Remicade_blue-pop.svg');
    background-repeat: no-repeat;
    background-position: top 40px left 50px;
    background-size: 201px;
  }
}

.swal2-popup #swal2-content p{
  text-align:left !important;
    img {
      margin-bottom: 10px;
    }
}

.swal2-popup .swal2-title {
  color: #0077c0 !important;
    font-size: 40px !important;
    font-weight: 500 !important;
    margin: 10px 30px 10px !important;
    text-align:left !important;
}
.swal2-popup .swal2-content{
  font-size: 18px !important;
  color:#1E1E1E !important;
  line-height: 1.1em !important;
  margin: 0px 20px 0px !important;
  font-weight: normal !important;

}

.swal2-popup .swal2-actions {
  margin-top: 0px !important;
}

.swal2-popup .swal2-close, .swal2-popup .swal2-close:hover {
  -webkit-transform: none !important;
  transform: none !important;
  color: #969696 !important;
  font-weight:bold !important;
  box-shadow: none !important;
}

.swal2-popup .swal2-styled.swal2-confirm, .swal2-popup .swal2-styled.swal2-cancel {
  border-radius: 0px !important;
  font-size: 17px !important;
    font-family: "Karbon-Medium" !important;
    background-color: #0077c0 !important;
    box-shadow: none !important;
    border: none !important;
    padding: 10px 20px 8px !important;
}


.swal2-popup .swal2-styled.swal2-cancel {
    background-color: #fff !important;
    color: #4D77C3 !important;
    box-shadow: none !important;
    margin-left: 40px;
}


.swal2-popup .swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: none !important;
}

@media screen and (max-width: 767px) {

  .swal2-popup {
    padding:1.25em 1em 3em !important;

    &.HCP_popup {
      background-position: top 40px left 35px;
    }
  }

  .swal2-popup .swal2-title { font-size: 24px !important; }

  .sweet-alert p { font-size:18px; }

  .swal2-popup .swal2-styled.swal2-confirm, .swal2-popup .swal2-styled.swal2-cancel{ width: 85%;}

  .swal2-popup .swal2-styled.swal2-cancel {
    margin-left:0px;
    margin-top: 20px;
  }

 }
